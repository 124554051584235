import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import './scss/ContactCard.scss';

const ContactCard = (props) => {
  let { contactHeading } = props;

  let data = props.content[props.language];
  let contactData = getContact(data);
  let { fields } = data.items[0];

  let contactFields = contactData.fields;
  let contact = contactFields;

  if (typeof (contact) === 'undefined' || Object.entries(contact).length === 0) return (<Col></Col>);

  return (
    <div className="ContactCard">
      <div className="container contact-container">

        <div className="body-text-contact-heading">
          <p>{contactHeading}</p>
        </div>
        <Row>
          <Col>
            <div className="body-text-contact">
              <p>{fields.siteName}</p>
              <p>{contact.mailingAddress}</p>
              <p>{contact.officeAddress}</p>
              <p>{contact.contactPerson}</p>
              <p>{contact.telephone}</p>
              <p>{contact.fax}</p>
              <p><a href={`mailto:${contact.email}`}>{contact.email}</a></p>
            </div>
          </Col>


        </Row>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    content: state.appState.contents,
    language: state.appState.currentLanguage
  }
}

const getContact = (data) => {
  const { fields } = data.items[0];
  const { includes } = data;
  if (typeof (includes) === 'undefined') return {};
  if (fields['contactDetails'] == null) return {};
  const { sys } = fields.contactDetails;
  const contact = includes.Entry.filter(x => x.sys.id === sys.id);
  return contact[0];
}

export default connect(mapStateToProps, {})(ContactCard);
