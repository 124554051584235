
const { NODE_ENV: env } = process.env;

export const FETCH_POSTS = 'FETCH_POSTS';
export const API_SPACE_ID = '5erxpguhwkrz';
const CF_ID = env === 'development' ? 'd1iyhqy7om0rko' : 'd1mehwhedtnyuw';
export const CF_BASE_URL = `https://${CF_ID}.cloudfront.net`;
const STAGE = env === 'development' ? 'staging' : 'master';
export const API_BASE_URL = `${CF_BASE_URL}/spaces/${API_SPACE_ID}/environments/${STAGE}/entries`;

export const CONTACT_EN = "Contact details";
export const CONTACT_FI = "Yhteystiedot";
export const CONTACT_SV = "Kontaktuppgifter";

export const LANG_EN = 'en';
export const LANG_FI = 'fi';
export const LANG_SV = 'sv';

export const SITE_SLUG = 'parator';
