import React from 'react';
import './components/scss/App.scss';
import Routes from './routes';

function App() {
  return (
    <div className="App">
      <Routes />
    </div>

  );
}

export default App;
